@use 'styles/base/mixins';
@use 'styles/base/variables';

@keyframes hotspot-pulse {
  0% {
    stroke-width: 6;
  }
  50% {
    stroke-width: 16;
  }
  100% {
    stroke-width: 6;
  }
}

.hotspots {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  g {
    cursor: pointer;
    pointer-events: all;

    &.isHidden {
      pointer-events: none;
    }

    &.hideCompletly {
      pointer-events: none;
      display: none;
    }

    &:hover,
    &.active {
      stroke: rgba(variables.$gb_gdds_cl17, 0.33);

      .dot {
        fill: variables.$gb_gdds_cl17;
      }
    }

    .corona {
      animation: hotspot-pulse 1.5s infinite ease;
    }
  }
}

.imageWrapper {
  position: relative;
  overflow: auto;
  width: 100%;
  z-index: 4;

  @include mixins.screen-sm {
    overflow: unset;
  }

  &.portrait {
    :global(.image--aspect-ratio) {
      overflow: hidden;

      @include mixins.screen-sm {
        height: 60vh;
        overflow: unset;
      }

      @include mixins.screen-md {
        height: auto;
      }

      :global(.image-content) {
        @include mixins.screen-sm {
          height: 60vh;
          width: fit-content;
        }

        @include mixins.screen-md {
          height: 100%;
          width: 100%;
        }
      }

      :global(.image-content-wrapper) {
        position: relative;

        @include mixins.screen-sm {
          height: 60vh;
          width: fit-content;
        }
      }

      img {
        width: 100%;
        height: auto;

        @include mixins.screen-sm {
          height: 100%;
        }
      }
    }
  }

  &.wide {
    :global(.image--aspect-ratio) {
      max-height: 60vh;
      height: 100%;

      @include mixins.screen-sm {
        max-height: unset;
        width: 100%;
        height: auto;
        overflow: hidden;
      }

      @include mixins.screen-md {
        overflow: unset;
      }
    }

    :global(.image-content-wrapper) {
      width: fit-content;
      position: relative;
      max-height: 60vh;
    }

    img {
      max-width: none;
      max-height: inherit;

      @include mixins.screen-sm {
        width: 100%;
        height: auto;
      }
    }
  }
}

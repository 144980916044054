div.wrapper {
  img {
    position: absolute;
    top: 0;
    width: 100%;

    &.newImg {
      opacity: 0;
      transition: opacity 0.2s linear;

      &.visible {
        opacity: 1;
      }
    }
  }
}

@use 'styles/base/mixins';
@use 'styles/base/variables';

.overlay {
  position: absolute;
  top: 1.25rem;
  right: 1rem;
  left: 1rem;
  background-color: variables.$gb_white;
  transition: opacity 500ms;
  display: block;
  z-index: 12;
  box-shadow: variables.$default-box-shadow;

  &.left {
    @include mixins.screen-sm {
      left: 2.5rem;
      right: unset;
    }
  }

  .contentWrapper {
    padding: 1.875rem;

    @include mixins.screen-sm {
      padding: 2rem;
    }
  }

  &.closed {
    opacity: 0;
    pointer-events: none;
  }

  @include mixins.screen-sm {
    top: 2.5rem;
    right: 2.5rem;
    left: unset;
    width: 30rem;
  }

  .closeButton {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
  }

  img {
    width: 100%;
  }
}

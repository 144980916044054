@use 'styles/base/mixins';
@use 'styles/base/variables';

.wrapper {
  margin: variables.$spacing-gdds-section-mobile-small;

  @include mixins.screen-md {
    margin: variables.$spacing-gdds-section-large;
  }

  &.bgGrey {
    background-color: variables.$gb_grey_050;
    margin: 0;
    padding: variables.$spacing-gdds-section-mobile-small;

    @include mixins.screen-md {
      padding: variables.$spacing-gdds-section-large;
    }
  }

  &.bgWhite {
    background-color: variables.$gb_white;
  }

  &.light {
    color: variables.$gb_white;

    .textWrapper a.link {
      color: variables.$gb_white;

      span svg {
        fill: variables.$gb_white;
      }

      &:hover span:nth-child(2) {
        border-bottom: 1px solid variables.$gb_white;
      }
    }
  }

  &.dark {
    color: variables.$gb_black;

    .textWrapper a.link,
    a::before,
    a:visited,
    a:hover {
      color: variables.$gb_black;
    }
  }

  &.left,
  &.right {
    .textWrapper {
      .link {
        @include mixins.screen-md {
          padding-bottom: 0;
        }
      }
    }
  }

  .textWrapper {
    &.headlineOnly {
      margin-bottom: 1rem;

      @include mixins.screen-md {
        margin-bottom: 0;
      }
    }

    &.textLeft {
      padding-right: 2rem;
    }

    h2 {
      margin-bottom: 1rem;

      @include mixins.screen-sm {
        margin-bottom: 1.5rem;
      }
    }

    p {
      margin-bottom: 1.5rem;
    }

    a.link {
      margin-bottom: 1rem;
      cursor: pointer;
    }
  }
}

.relativeContainer {
  position: relative;
}

.overlay {
  z-index: 5;
  position: absolute;
  max-width: 24rem;
  width: 100%;
  background-color: variables.$gb_white;
  box-shadow: 0 0 6px rgba(variables.$gb_black, 0.29);
  visibility: hidden;
  transition: opacity 500ms;

  img {
    width: 100%;
  }

  &.closed {
    opacity: 0;
    pointer-events: none;
  }

  .overlayText {
    padding: 1.875rem;
    color: variables.$gb_black;
    font-size: 1rem;

    @include mixins.screen-md {
      padding: 2rem;
    }

    .overlayHeadline {
      margin-bottom: 1.25rem;

      h3 {
        margin: unset;
      }
    }

    .closeIcon {
      border: 0;
      float: right;
      padding: 0.5rem;
      width: 2.5rem;
      margin: -1.4rem;
      cursor: pointer;
      min-width: 0;

      > span {
        width: 0.875rem;

        svg {
          width: inherit;
        }
      }
    }

    .link {
      margin-top: 0.625rem;
      color: variables.$gb_black;
      font-size: 1rem;
      cursor: pointer;

      &:active {
        color: variables.$gb_white;

        span svg {
          fill: variables.$gb_white;
        }
      }

      &::before {
        display: none;
      }

      &:hover {
        text-decoration: none;
      }

      span svg {
        fill: variables.$gb_black;
      }
    }
  }
}
